import axios from "axios";
import React, { useState } from "react";
import { TbFileOrientation } from "react-icons/tb";
import { NavLink, useNavigate } from "react-router-dom";
import { IMAGES } from "../../../assets/images/images";
import { LOGOUT } from "../../../services/URL";
import Loader from "../Loader/Loader";
import classes from "./Index.module.css";
import { ADMIN_VIDEO_FORM_ROUTE } from "../../../Constant/Index";

const Navbar = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // New state to handle sidebar visibility

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const companyId = localStorage.getItem("companyId");
  const siteId = localStorage.getItem("siteId");
  const handleSignout = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      // setIsLoading(true);
      const payload = {
        id: localStorage.getItem("user"),
      };
      const response = await axios.post(LOGOUT, payload, {
        headers,
      });
      localStorage.removeItem("users");
      localStorage.removeItem("user");
      localStorage.removeItem("type");
      localStorage.removeItem("companyId");
      localStorage.removeItem("token");
      // setIsLoading(false);
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };
  const handleClick = () => {
    navigate("/dashboard");
  };
  return (
    <div
      className={`${classes.sidebar} ${
        isSidebarOpen ? `${classes.open}` : ""
      } side-bar-edit`}
    >
      {/* Hamburger button */}
      <div className={`${classes.hamburger}`} onClick={handleToggleSidebar}>
        {/* <div className={`${classes.line}`}></div>
        <div className={`${classes.line}`}></div>
        <div className={`${classes.line}`}></div> */}
        <img
          src={IMAGES.hamBurger}
          alt="Ham"
          className={`${classes.hamBurgerIcon}`}
        />
        <img
          src={IMAGES.crossHam}
          alt="Cross"
          className={`${classes.hamCross}`}
        />
      </div>
      <div className={`${classes.logo} cursor-pointer`} onClick={handleClick}>
        <img src={props.logo} alt="Logo" />
      </div>
      <nav className={`${classes.listingWrapper} pt-4`}>
        <ul className="ps-0">
          {props.userRole === "admin" ? (
            <>
              <li>
                <NavLink
                  exact="true"
                  to="/sites"
                  className={`${classes.activeNavLink} d-flex align-items-center`}
                >
                  <img
                    className="pe-2 dashboard-active-link"
                    src={IMAGES.userlink}
                    alt=""
                  />
                  <img
                    className="pe-2 dashboard-nonactive-link"
                    src={IMAGES.userblack}
                    alt=""
                  />

                  {props.navLink3}
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  exact="true"
                  to="/companies"
                  className={`${classes.activeNavLink} d-flex align-items-center`}
                >
                  <FaBuildingUser fontSize="24px" />
                  <div className="ps-2">{props.navlink5}</div>
                </NavLink>
              </li> */}
            </>
          ) : (
            <>
              <li>
                <NavLink
                  exact="true"
                  to="/dashboard"
                  className={`${classes.activeNavLink} d-flex align-items-center`}
                >
                  <img
                    className="pe-2 dashboard-nonactive-link"
                    src={IMAGES.dashboardlink}
                    alt=""
                  />{" "}
                  <img
                    className="pe-2 dashboard-active-link"
                    src={IMAGES.dashboardgre}
                    alt=""
                  />{" "}
                  {props.navLink1}
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact="true"
                  to="/users"
                  className={`${classes.activeNavLink} d-flex align-items-center`}
                >
                  <img
                    className="pe-2 dashboard-active-link"
                    src={IMAGES.userlink}
                    alt=""
                  />
                  <img
                    className="pe-2 dashboard-nonactive-link"
                    src={IMAGES.userblack}
                    alt=""
                  />

                  {props.navLink2}
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact="true"
                  to="/employee"
                  className={`${classes.activeNavLink} d-flex align-items-center`}
                >
                  <img
                    className="pe-2 dashboard-active-link"
                    src={IMAGES.employeeGreenImage}
                    alt=""
                  />
                  <img
                    className="pe-2 dashboard-nonactive-link"
                    src={IMAGES.employeeImage}
                    alt=""
                  />

                  {props.navlink4}
                </NavLink>
              </li>
              {companyId === "San4358NG" && (
                <>
                  <li>
                    <NavLink
                      exact="true"
                      to="/job-orientation"
                      className={`${classes.activeNavLink} d-flex align-items-center`}
                    >
                      <TbFileOrientation fontSize="24px" className="me-2" />

                      {props.navlink5}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact="true"
                      to={`/admin-video-form`}
                      className={`${classes.activeNavLink} d-flex align-items-center`}
                    >
                      <img
                        className="pe-2 dashboard-active-link"
                        src={IMAGES.employeeGreenImage}
                        alt=""
                      />
                      <img
                        className="pe-2 dashboard-nonactive-link"
                        src={IMAGES.employeeImage}
                        alt=""
                      />

                      {props.navlink6}
                    </NavLink>
                  </li>
                </>
              )}
            </>
          )}
        </ul>
      </nav>

      <div className={`${classes.sideBarFooter} d-flex justify-content-center`}>
        <span className={`${classes.signOut}`} onClick={handleSignout}>
          <img className="pe-3" src={IMAGES.signOut} alt="" />
          Sign Out
        </span>
      </div>
      <span className={classes.presentedByText}>BY SANICO USA</span>
      {isLoading && <Loader />}
    </div>
  );
};

export default Navbar;
