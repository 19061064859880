import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import Compressor from "compressorjs";
import style from "./VideoForm.module.css";
import { IMAGES } from "../../assets/images/images";
import { useParams } from "react-router-dom";
import {
  MARK_VIDEO_AS_WATCHED,
  VIDEO_FORM_SUBMIT,
  SAVE_VIDEO_PROGRESS,
  GET_VIDEO_PROGRESS,
  GET_VIDEO_DETAILS,
  UPLOAD_VIDEO_IMAGES,
} from "../../services/URL";
import InputFields from "../Common/InputField/InputFields";
import ButtonUser from "../Common/Button/ButtonUser";

const VideoForm = () => {
  const [User, setUser] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [videoWatched, setVideoWatched] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [hasCompleted, setHasCompleted] = useState(false);
  const [initialTime, setInitialTime] = useState(0);
  const [isThankyouMsg, setIsThankyouMsg] = useState(false);
  const videoRef = useRef(null);
  const cameraRef = useRef(null);
  const canvasRef = useRef(null);
  const [cameraAccessGranted, setCameraAccessGranted] = useState(false);
  const [cameraAccessDenied, setCameraAccessDenied] = useState(false);
  const [currentPart, setCurrentPart] = useState(1);
  const [segmentDuration, setSegmentDuration] = useState();
  const [videoDetails, setVideoDetails] = useState();
  const { companyId, siteId } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [photoCount, setPhotoCount] = useState(() => {
    const savedPhotoCount = localStorage.getItem("photoCount");
    return savedPhotoCount ? parseInt(savedPhotoCount, 10) : 0;
  });

  let initialPartCaptured = { part1: false, part2: false, part3: false };
  const savedPartCaptured = localStorage.getItem("partCaptured");

  if (savedPartCaptured) {
    try {
      initialPartCaptured = JSON.parse(savedPartCaptured);
    } catch (error) {
      initialPartCaptured = { part1: false, part2: false, part3: false };
    }
  }

  const partCaptured = useRef(initialPartCaptured);

  useEffect(() => {
    localStorage.setItem("photoCount", photoCount);
    localStorage.setItem("partCaptured", JSON.stringify(partCaptured.current));
  }, [photoCount, partCaptured.current]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      const preventSpeedChange = () => {
        videoElement.playbackRate = 1;
      };
      videoElement.addEventListener("ratechange", preventSpeedChange);

      const preventContextMenu = (e) => e.preventDefault();
      videoElement.addEventListener("contextmenu", preventContextMenu);

      return () => {
        if (videoElement) {
          videoElement.removeEventListener("ratechange", preventSpeedChange);
          videoElement.removeEventListener("contextmenu", preventContextMenu);
        }
      };
    }
  }, [videoRef]);

  // Compress and upload image to S3
  const compressAndUpload = async (file, onError, onSuccess) => {
    const maxSize = 1 * 1024 * 1024;
    if (file.size > maxSize) {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 600,
        async success(compressedFile) {
          if (compressedFile.size > maxSize) {
            onError("File size exceeds 1 MB limit after compression.");
          } else {
            try {
              const uploadResult = await uploadCapturedPhoto(compressedFile);
              onSuccess(uploadResult);
            } catch (error) {
              onError("Upload failed: " + error.message);
            }
          }
        },
        error(err) {
          onError("Compression failed: " + err.message);
        },
      });
    } else {
      try {
        const uploadResult = await uploadCapturedPhoto(file);
        onSuccess(uploadResult);
      } catch (error) {
        onError("Upload failed: " + error.message);
      }
    }
  };

  const uploadCapturedPhoto = async (file) => {
    const formData = new FormData();
    formData.append("email", userEmail);
    formData.append("image", file);

    const response = await axios.post(UPLOAD_VIDEO_IMAGES, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.imageUrl;
  };

  const captureUserPhoto = async () => {
    if (photoCount >= 3) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user" },
      });
      const video = cameraRef.current;
      video.srcObject = stream;

      video.onloadedmetadata = () => {
        video.play();
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          const file = new File([blob], `photo_${photoCount + 1}.png`, {
            type: "image/png",
          });

          compressAndUpload(
            file,
            (error) => {
              console.error("Photo upload error:", error);
              setErrorMessage(error);
            },
            (imageUrl) => {
              console.log("Photo uploaded successfully:", imageUrl);
              setPhotoCount((prev) => prev + 1);
            }
          );

          stream.getTracks().forEach((track) => track.stop());
        }, "image/png");
      };
    } catch (error) {
      console.error("Error accessing the camera:", error);
      setErrorMessage("Camera access failed.");
    }
  };

  const handleVideoTimeUpdate = useCallback(() => {
    const video = videoRef.current;

    if (!segmentDuration || !video || videoWatched) return;

    if (video && userEmail && !videoWatched) {
      const { currentTime, duration } = video;
      if (currentTime <= segmentDuration) {
        setCurrentPart(1);
        if (!partCaptured.current.part1 && photoCount < 3) {
          captureUserPhoto();
          partCaptured.current.part1 = true;
        }
      } else if (currentTime <= segmentDuration * 2) {
        setCurrentPart(2);
        if (!partCaptured.current.part2 && photoCount < 3) {
          captureUserPhoto();
          partCaptured.current.part2 = true;
        }
      } else if (currentTime <= segmentDuration * 3) {
        setCurrentPart(3);
        if (!partCaptured.current.part3 && photoCount < 3) {
          captureUserPhoto();
          partCaptured.current.part3 = true;
        }
      }

      if (Math.floor(currentTime) === Math.floor(duration)) {
        handleVideoCompletion();
        saveVideoProgress(currentTime);
      }
    }
  }, [photoCount, userEmail, videoWatched, segmentDuration]);

  const getFormDetail = async () => {
    try {
      const response = await axios.post(GET_VIDEO_DETAILS, {
        companyId,
        siteId,
      });
      const details = response.data?.videoDetails || {
        jobTitle: "",
        videoLink: "", // Now expecting a direct video file URL (e.g., MP4)
        notes: "",
        jobVisibility: false,
      };
      setVideoDetails(details);
    } catch (error) {
      console.error("Error fetching video details:", error);
    }
  };

  useEffect(() => {
    getFormDetail();
  }, [companyId, siteId]);

  useEffect(() => {
    if (userEmail && userEmail !== "") {
      axios
        .post(GET_VIDEO_PROGRESS, { email: userEmail })
        .then((response) => {
          if (response.data.watchedVideo) {
            setVideoWatched(true);
          }
          setInitialTime(response.data?.lastWatchedTime || 0);
        })
        .catch((error) => {
          console.error("Error fetching video progress:", error);
        });
    }
  }, [userEmail]);

  useEffect(() => {
    const UserEmail = localStorage.getItem("userEmail");
    requestCameraAccess();
    if (UserEmail) {
      setUserEmail(UserEmail);
      setIsFormSubmitted(true);
    }
  }, []);

  // Handle tab close or switch and resume video
  useEffect(() => {
    const video = videoRef.current;

    if (video && initialTime > 0) {
      video.currentTime = initialTime;
    }

    const handleVisibilityChange = () => {
      if (video && !videoWatched) {
        if (document.hidden) {
          video.pause();
          saveVideoProgress(video.currentTime);
        }
      }
    };

    const handleBeforeUnload = () => {
      if (video && userEmail && !videoWatched) {
        saveVideoProgress(video.currentTime);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [initialTime, userEmail, videoWatched]);

  const requestCameraAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) {
        setCameraAccessGranted(true);
        setCameraAccessDenied(false);
        stream.getTracks().forEach((track) => track.stop());
      }
    } catch (error) {
      console.error("Camera access denied:", error);
      setCameraAccessGranted(false);
      setCameraAccessDenied(true);
    }
  };

  const onSubmit = async (data) => {
    try {
      const body = { ...data, companyId, siteId };
      const response = await axios.post(VIDEO_FORM_SUBMIT, body);
      if (response.data.data) {
        setUser(response.data.data);
        setHasCompleted(response.data.data.watchedVideo);
        setUserEmail(response.data.data.email);
      }
      setIsFormSubmitted(true);
      localStorage.setItem("userEmail", response.data.data.email);
      reset();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleVideoCompletion = async () => {
    try {
      const response = await axios.post(MARK_VIDEO_AS_WATCHED, {
        email: userEmail,
      });
      if (response.data.data) {
        setVideoWatched(true);
        setHasCompleted(true);
      }
    } catch (error) {
      console.error("Error updating video status:", error);
    }
  };

  const handleLoadedMetadata = () => {
    const video = videoRef.current;
    if (video && initialTime > 0) {
      video.currentTime = initialTime;
    }
    const totalDuration = video.duration;
    if (totalDuration) {
      const segmentTime = totalDuration / 3;
      setSegmentDuration(segmentTime);
    }
  };

  const saveVideoProgress = async (currentTime) => {
    try {
      await axios.post(SAVE_VIDEO_PROGRESS, {
        email: userEmail,
        currentTime: currentTime,
      });
    } catch (error) {
      console.error("Error saving video progress:", error);
    }
  };

  const handleLogOut = () => {
    setIsFormSubmitted(false);
    setIsThankyouMsg(false);
    setUserEmail("");
    setUser("");
    setVideoWatched(false);
    setErrorMessage("");
    setSegmentDuration(0);
    setPhotoCount(0);
    partCaptured.current = { part1: false, part2: false, part3: false };
    reset();
    localStorage.removeItem("userEmail");
    localStorage.removeItem("photoCount");
    localStorage.removeItem("partCaptured");
  };

  const handleResubmitError = () => {
    setErrorMessage("Please watch the full video before proceeding.");
  };

  const thankyouMsg = () => (
    <div className={style.completionScreen}>
      <div className={style.logoContainer}>
        <img className="mb-3" src={IMAGES.FormLogo} alt="logo" />
      </div>
      <p className={style.completionMessage}>
        You have Completed Watching Your Jobsite Orientation Video
      </p>
      <button onClick={handleLogOut} className={`${style.proceedButton}`}>
        Log Out
      </button>
    </div>
  );

  const supportMessage = () => (
    <div className={style.completionScreen}>
      <div className={style.logoContainer}>
        <img className="mb-3" src={IMAGES.FormLogo} alt="logo" />
      </div>
      <p className={style.completionMessage}>
        You have not Jobsite Orientation Access please connect to support
      </p>
    </div>
  );

  // Handle change function for InputFields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value); // Update the form state using setValue from useForm
  };

  return (
    <div className={style.videoFormContainer}>
      {!videoDetails || !videoDetails.jobVisibility ? (
        supportMessage()
      ) : !isFormSubmitted ? (
        <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
          <h2 className={style.jobTitle}>{videoDetails.jobTitle}</h2>
          <div className={style.logoContainer}>
            <img className="mb-1" src={IMAGES.FormLogo} alt="logo" />
          </div>
          <h2 className={style.notes}>{videoDetails.notes}</h2>

          <div className={style.formGroup}>
            <InputFields
              type="text"
              label="Name"
              placeholder="Name"
              autoComplete="off"
              name="name"
              value={undefined} // Controlled by react-hook-form
              handleChange={handleChange}
              {...register("name", { required: "Name is required" })}
              className={style.input}
            />
            {errors.name && (
              <p className={style.errorMessage}>{errors.name.message}</p>
            )}
          </div>

          <div className={style.formGroup}>
            <InputFields
              type="email"
              placeholder="Email"
              label="Email"
              autoComplete="off"
              name="email"
              value={undefined} // Controlled by react-hook-form
              handleChange={handleChange}
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email address",
                },
              })}
              className={style.input}
            />
            {errors.email && (
              <p className={style.errorMessage}>{errors.email.message}</p>
            )}
          </div>

          <div className={style.formGroup}>
            <Controller
              name="phone"
              label="Phone"
              control={control}
              defaultValue=""
              rules={{
                required: "Phone is required",
                pattern: {
                  value: /^\+\d{1,3}\s?\d{3}\s?\d{3}\s?\d{4}$/,
                  message: "Invalid phone number (+x xxx xxx xxxx).",
                },
              }}
              render={({ field }) => (
                <>
                  <InputFields
                    type="phone"
                    label="Phone"
                    placeholder="+1 625 999 3488"
                    value={field.value}
                    index="phone"
                    maxLength={65}
                    handleChange={(e) => field.onChange(e)}
                    required
                  />
                  {errors.phone && (
                    <div className={`${style.errorMessage}`}>
                      {errors.phone.message}
                    </div>
                  )}
                </>
              )}
            />
          </div>

          <ButtonUser variant="success" label="Submit" type="submit" />
        </form>
      ) : isThankyouMsg ? (
        thankyouMsg()
      ) : cameraAccessGranted ? (
        <div className={style.videoContainer}>
          <div className={style.logoContainer}>
            <img className="mb-3" src={IMAGES.FormLogo} alt="logo" />
          </div>
          <video
            ref={videoRef}
            width="600"
            controls
            onLoadedMetadata={handleLoadedMetadata}
            onTimeUpdate={handleVideoTimeUpdate}
            className={`${videoWatched ? style.watchedVideo : style.video}`}
          >
            <source src={videoDetails.videoLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video ref={cameraRef} style={{ display: "none" }}></video>
          <canvas
            ref={canvasRef}
            width="640"
            height="480"
            style={{ display: "none" }}
          ></canvas>

          {!videoWatched && (
            <p className={style.errorMessage}>{errorMessage}</p>
          )}
          <button
            disabled={!videoWatched}
            onClick={
              videoWatched ? () => setIsThankyouMsg(true) : handleResubmitError
            }
            className={`${style.proceedButton} ${
              videoWatched ? "" : style.disabled
            }`}
          >
            Proceed
          </button>
        </div>
      ) : cameraAccessDenied ? (
        <div className={style.accessDeniedContainer}>
          <p className={style.accessDeniedMessage}>
            Please allow camera access to watch the video.
          </p>
        </div>
      ) : (
        <div className={style.loadingMessage}>
          Checking for camera permissions...
        </div>
      )}
    </div>
  );
};

export default VideoForm;
