// EmployeeDataTable.js
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { IoCheckmarkOutline, IoCopyOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { IMAGES } from "../../../assets/images/images";
import InputFields from "../../Common/InputField/InputFields";
import style from "../AdminPage.module.css";

const EmployeeDataTable = ({
  employees,
  formatTime,
  formatDate,
  handleCopyLink,
  copied,
  videoLinkURL,
  companyId,
  siteId,
}) => {
  const [searchText, setSearchText] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState(employees);
  // const SERVERBASEURL = "https://stagingapi.safesiteusa.com";

  // const SERVERBASEURL="http://localhost:3000";// localhost
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    setFilteredEmployees(
      employees.filter(
        (employee) =>
          employee.name.toLowerCase().startsWith(value) ||
          employee.phone.toLowerCase().startsWith(value) ||
          employee.email.toLowerCase().startsWith(value)
      )
    );
  };
  useEffect(() => {
    setFilteredEmployees(employees);
  }, [employees]);
  // Define the columns for the DataTable
  const columns = [
    {
      name: <b>#</b>,
      selector: (_, index) => index + 1,
      sortable: false,
      width: "50px",
    },
    {
      name: <b>Name</b>,
      selector: (employee) => employee.name,
      sortable: true,
    },
    {
      name: <b>Phone</b>,
      selector: (employee) => employee.phone,
      sortable: true,
    },
    {
      name: <b>Email</b>,
      selector: (employee) => employee.email,
      sortable: true,
    },
    {
      name: <b>Video Watched</b>,
      selector: (employee) => (employee.watchedVideo ? "✔️" : "❌"),
      center: true, // Center align the icon
    },
    {
      name: <b>Video Watched At</b>,
      selector: (employee) =>
        employee.watchedVideo
          ? formatDate(employee.videoWatchedAt)
          : "Incomplete",
      sortable: true,
    },
    {
      name: <b>Last Watched Time</b>,
      selector: (employee) => formatTime(employee.lastWatchedTime),
      sortable: true,
    },
    {
      name: <b>Employee Images</b>,
      selector: (employee) => (
        <>
          <img
            src={`${employee.images[0]}`}
            alt={employee.name}
            className={`${style.employeeImages}`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = IMAGES.avatarImage;
            }}
          />
          <img
            src={`${employee.images[1]}`}
            alt={employee.name}
            className={`ms-2 ${style.employeeImages}`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = IMAGES.avatarImage;
            }}
          />
          <img
            src={`${employee.images[2]}`}
            alt={employee.name}
            className={`ms-2 ${style.employeeImages}`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = IMAGES.avatarImage;
            }}
          />
        </>
      ),
    },
  ];

  return (
    <div className={style.dataTableWrapper}>
      <div className={style.searchContainer}>
        <div className=" mt-2 ">
          Go to:{" "}
          <Link
            to={`/video-form/companies/${companyId}/sites/${siteId}`}
            className="text-primary text-decoration-underline fs-7 me-2"
          >
            {videoLinkURL}
          </Link>
          {copied ? (
            <IoCheckmarkOutline
              title="Copied!"
              fontSize="18"
              style={{
                cursor: "pointer",
                color: "green",
              }}
            />
          ) : (
            <IoCopyOutline
              title="Copy"
              fontSize="18"
              style={{ cursor: "pointer" }}
              onClick={handleCopyLink}
            />
          )}
        </div>
        <InputFields
          type="text"
          cssClass={style.searchInput}
          placeholder="Search employees..."
          value={searchText}
          handleChange={(e) => handleSearch(e)}
          // handleKeyDown={handleKeyDown}
        />
      </div>
      <DataTable
        columns={columns}
        data={filteredEmployees}
        pagination
        highlightOnHover
      />
    </div>
  );
};

// PropTypes for type checking
EmployeeDataTable.propTypes = {
  employees: PropTypes.array.isRequired,
  formatTime: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired,
};

export default EmployeeDataTable;
